import "./App.css";

import React from "react";
import { inject, observer } from "mobx-react";
import { Step } from "./Components/Step";
import ImageStep from "./Components/ImageStep";
import GridStep from "./Components/GridStep";

import CountUp from "react-countup";
import SummaryStep from "./Components/SummaryStep";
import PdfStep from "./Components/PdfStep";
import CompleteStep from "./Components/CompleteStep";
import PaymentStep from "./Components/PaymentStep";
import { Translation } from "./Translation";
import { appConfig } from "./config";
import { Loading } from "./Components/Loading";
import { SpecPopup } from "./Components/SpecPopup";
import DependencyPopup from "./Components/DependencyPopup";
import DependantPopup from "./Components/DependantPopup";
import BrokerStep from "./Components/BrokerStep";
import FactoryFormStep from "./Components/FactoryFormStep";
import Modal from "react-animated-modal";
import { convertCurrency, convertCurrencyLocation } from "./helpers";

@inject("AppStore")
@observer
class App extends React.Component {
  store = this.props.AppStore;

  async componentDidMount() {
    const { slug, location, number } = this.props.match.params;

    await this.store.getLocation(location);
    this.store.loadConfig();

    // get path
    this.store.init(slug, number);
  }

  render() {
    const { page, data } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              <img
                src={appConfig.locale.api + this.store.location.logo.url}
                className="logo"
                alt=""
              />
            </a>
          </div>
          <div
            className="steps"
            style={page.hideSteps ? { display: "none" } : null}
          >
            {!this.store.page.completed && (
              <React.Fragment>
                {data.steps.map((step, i) => (
                  <Step
                    key={i}
                    order={i + 1}
                    visited={i <= page.stepIndex}
                    selected={i === page.stepIndex}
                    onSelected={() => {
                      this.store.onStepSelected(step, i);
                    }}
                    title={step[this.store.langify("title")]}
                    color={this.store.data.color}
                  />
                ))}
                <Step
                  order={page.summaryStep}
                  visited={page.summaryStep <= page.stepIndex}
                  selected={page.summaryStep <= page.stepIndex}
                  onSelected={() => {
                    this.store.onStepSelected({}, page.summaryStep);
                  }}
                  title={this.store.page.lang === "en" ? "SUMMARY" : "ÖZET"}
                />
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="content">
          {page.completeStep !== page.stepIndex && (
            <React.Fragment>
              {!page.hideSteps &&
                data.steps.map((step, i) => (
                  <div
                    key={i}
                    className={`step-content-inner visible-mobile ${i === page.stepIndex &&
                      "flex animate__animated animate__fadeIn"
                      }`}
                    data-title={step.title}
                  >
                    <div className="title">
                      <span>{step[this.store.langify("title")]}</span>
                    </div>
                    {step.type === "Image" && (
                      <ImageStep store={this.store} step={step} data={data} />
                    )}
                    {step.type === "Grid" && (
                      <GridStep store={this.store} step={step} />
                    )}
                  </div>
                ))}
              <div
                id="step-summary"
                className={`step-content-inner visible-mobile ${page.summaryStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="SUMMARY"
              >
                <div className="title" id="summary">
                  {this.store.page.lang === "en" ? "SUMMARY" : "ÖZET"}
                </div>
                <SummaryStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${page.pdfStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="Pdf"
              >
                <div className="title" id="pdf">
                  PDF
                </div>
                <PdfStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${page.paymentStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="Payment"
              >
                <div className="title" id="payment">
                  Payment
                </div>
                <PaymentStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${page.brokerStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="Broker"
              >
                <div className="title" id="broker">
                  BROKER
                </div>
                <BrokerStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${page.factoryStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="Broker"
              >
                <div className="title" id="broker">
                  FACTORY FORM
                </div>
                <FactoryFormStep store={this.store} />
              </div>
            </React.Fragment>
          )}

          {page.completeStep === page.stepIndex && (
            <div
              className={`step-content-inner ${"flex animate__animated animate__fadeIn"}`}
              data-title="Complete"
            >
              <CompleteStep store={this.store} />
            </div>
          )}
        </div>
        <div
          className="footer"
          style={{
            display: page.completeStep === page.stepIndex ? "none" : "flex",
          }}
        >
          {!this.store.page.completed && (
            <React.Fragment>
              <div className="footer-left">
                <div className="footer-model">{this.store.data.flat_name}</div>
                <div className="footer-spec">
                  <SpecPopup model={this.store.data} />
                </div>
                <div className="footer-price">
                  <div className="footer-price-title">PURCHASE PRICE</div>
                  <div>
                    <CountUp
                      className="price-text"
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix={this.store.location.symbol}
                      duration={1}
                      start={this.store.lastPrice}
                      end={this.store.summary.purchase_price}
                    />
                  </div>
                </div>
              </div>
              <div className="delivery">
                <div className="button-wrapper">
                  {page.stepIndex === 0 && (
                    <div
                      className="modelSelector__cleanbutton"
                      style={{ marginRight: 15 }}
                    >
                      <a href={`/`}>
                        <Translation lang={this.store.page.lang} t="prev" />
                      </a>
                    </div>
                  )}

                  {page.stepIndex > 0 &&
                    (page.stepIndex === page.paymentStep ||
                      page.stepIndex === page.brokerStep ||
                      page.stepIndex === page.factoryStep ? (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.summaryStep)
                          }
                        >
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ) : (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a onClick={this.store.prevStep}>
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ))}

                  {page.stepIndex === page.summaryStep &&
                    this.store.user &&
                    this.store.user.user.factory_order && (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.factoryStep)
                          }
                        >
                          FACTORY ORDER
                        </a>
                      </div>
                    )}

                  {page.stepIndex === page.summaryStep &&
                    (this.store.broker ? (
                      <React.Fragment>
                        <div className="modelSelector__cleanbutton">
                          <a
                            onClick={() =>
                              this.store.onBrokerageStepSelected(
                                {},
                                this.store.page.brokerStep
                              )
                            }
                          >
                            SALES AGREEMENT
                          </a>
                        </div>
                        <div
                          className="modelSelector__cleanbutton"
                          style={{ marginLeft: 15 }}
                        >
                          <a
                            onClick={() =>
                              this.store.onStepSelected(
                                {},
                                this.store.page.pdfStep
                              )
                            }
                          >
                            SEND PDF
                          </a>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="modelSelector__cleanbutton">
                          <a
                            onClick={() =>
                              this.store.onStepSelected(
                                {},
                                this.store.page.pdfStep
                              )
                            }
                          >
                            SEND PDF
                          </a>
                        </div>
                        <div
                          className="modelSelector__cleanbutton"
                          style={{ marginLeft: 15 }}
                        >
                          <a onClick={() => (this.store.payment_modal = true)}>
                            ORDER NOW
                          </a>
                        </div>
                      </React.Fragment>
                    ))}

                  {page.stepIndex === page.pdfStep && (
                    <React.Fragment>
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={(e) => {
                            var form = document.getElementById("pdf_form");

                            if (form.reportValidity()) {
                              this.store.onPdfSubmit(e);
                            }
                          }}
                        >
                          SEND PDF
                        </a>
                      </div>
                      {this.store.user && (
                        <div className="modelSelector__cleanbutton">
                          <a
                            onClick={(e) => {
                              this.store.onPdfDownload(e);
                            }}
                          >
                            DOWNLOAD PDF
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  {page.stepIndex < page.summaryStep &&
                    (this.store.checkNext ? (
                      this.store.page.nextEnabled ? (
                        <div className="modelSelector__cleanbutton">
                          <a onClick={this.store.nextStep}>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      ) : (
                        <div className="modelSelector__cleanbutton disabled">
                          <a>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      )
                    ) : (
                      <div className="modelSelector__cleanbutton disabled">
                        <a title="You must select at least one option">
                          <Translation lang={this.store.page.lang} t="next" />
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <Modal
          visible={this.store.payment_modal}
          closemodal={() => (this.store.payment_modal = false)}
          type="zoomIn"
        >
          <div
            className="modal-content"
            style={{
              padding: 25,
              width: "50vw",
              height: "80vh",
              overflow: "auto",
              boxSizing: "border-box",
            }}
          >
            {this.store.page.error && (
              <div style={{ color: "indianred" }}>{this.store.page.error}</div>
            )}

            <div className="order-info" style={{ paddingLeft: 15 }}>
              <h2 style={{ marginBottom: 15 }}>
                YOUR {this.store.data.flat_name}
              </h2>

              <div
                style={{
                  display: "flex",
                  fontSize: 14,
                  marginBottom: 5,
                  paddingBottom: 5,
                  borderBottom: "1px solid #ccc",
                }}
              >
                <div style={{ flex: 1 }}>Price</div>
                <div>
                  {convertCurrency(
                    this.store.summary.purchase_price,
                    this.store.location
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: 14,
                  marginBottom: 5,
                }}
              >
                <div style={{ flex: 1 }}>Due today</div>
                <div>
                  {convertCurrencyLocation(
                    this.store.data.due_today_price[
                    this.store.location.currency
                    ],
                    this.store.location
                  )}
                </div>
              </div>
              <div
                style={{
                  fontSize: 12,
                  color: "#aaa",
                  textAlign: "right",
                }}
              >
                Non-refundable Order Fee
              </div>
            </div>

            <form id="payment_form" onSubmit={this.store.onPaymentSubmit}>
              <h2 style={{ paddingLeft: 15 }}>Card Information</h2>

              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  fontSize: 12,
                  lineHeight: 1.4,
                }}
              >
                A{" "}
                {convertCurrencyLocation(
                  this.store.data.due_today_price[this.store.location.currency],
                  this.store.location
                )}{" "}
                payment through credit card is due today and the remaining 50%
                down payment via wire transfer must be made within 5 days after
                making your online Onda order. Your Onda order is not considered
                confirmed until 50% down payment has been received.
              </div>

              <div className="form">
                <div className="form-item">
                  <input
                    required
                    type="text"
                    name="name"
                    placeholder="Name on Card"
                    value={this.store.paymentForm.name}
                    onChange={(e) =>
                      (this.store.paymentForm.name = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="number"
                    name="card_number"
                    placeholder="Credit Card Number"
                    value={this.store.paymentForm.cardnumber}
                    onChange={(e) =>
                      (this.store.paymentForm.cardnumber = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="number"
                    name="exp_month"
                    placeholder="Exp. Month"
                    value={this.store.paymentForm.exp_month}
                    onChange={(e) =>
                      (this.store.paymentForm.exp_month = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="number"
                    name="exp_year"
                    placeholder="Exp. Year"
                    value={this.store.paymentForm.exp_year}
                    onChange={(e) =>
                      (this.store.paymentForm.exp_year = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="number"
                    name="cvv"
                    placeholder="CVV"
                    value={this.store.paymentForm.cvv}
                    onChange={(e) =>
                      (this.store.paymentForm.cvv = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    type="text"
                    name="zipcode"
                    placeholder="Billing zip code"
                    value={this.store.paymentForm.zipcode}
                    onChange={(e) =>
                      (this.store.paymentForm.zipcode = e.target.value)
                    }
                  />
                </div>
              </div>

              <h2 style={{ paddingLeft: 15 }}>YOUR INFORMATION</h2>
              <div className="form">
                <div className="form-item">
                  <input
                    required
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    value={this.store.detailsForm.firstname}
                    onChange={(e) =>
                      (this.store.detailsForm.firstname = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    value={this.store.detailsForm.lastname}
                    onChange={(e) =>
                      (this.store.detailsForm.lastname = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={this.store.detailsForm.email}
                    onChange={(e) =>
                      (this.store.detailsForm.email = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    required
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={this.store.detailsForm.phone}
                    onChange={(e) =>
                      (this.store.detailsForm.phone = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={this.store.detailsForm.address}
                    onChange={(e) =>
                      (this.store.detailsForm.address = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={this.store.detailsForm.city}
                    onChange={(e) =>
                      (this.store.detailsForm.city = e.target.value)
                    }
                  />
                </div>
                <div className="form-item">
                  <select
                    value={this.store.detailsForm.state}
                    onChange={(e) =>
                      (this.store.detailsForm.state = e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select Your State
                    </option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="District Of Columbia">
                      District Of Columbia
                    </option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                </div>
                <div className="form-item">
                  <input
                    type="text"
                    name="zipcode"
                    placeholder="Zip Code"
                    value={this.store.detailsForm.zipcode}
                    onChange={(e) =>
                      (this.store.detailsForm.zipcode = e.target.value)
                    }
                  />
                </div>
              </div>

              <div style={{ padding: 15, fontSize: 12 }}>
                All terms and conditions of the Onda Boats USA{" "}
                <a
                  href="https://ondaboatsusa.com/wp-content/uploads/Onda%20Boats%202022-23%20Models%20Limited%20Warranty_Exhibit%20B.pdf"
                  target="_blank"
                >
                  Order Agreement
                </a>{" "}
                as well as this Privacy Notice are accepted by placing this
                purchase.
              </div>

              <div
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  fontSize: 12,
                  lineHeight: 1.4,
                }}
              >
                By submitting your email, you are opting in to receive emails
                from {this.store.location.brand}
              </div>

              <div style={{ textAlign: "center", marginTop: 30 }}>
                <button type="submit" className="button__clean">
                  ORDER NOW
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <DependencyPopup />
        <DependantPopup />
      </div>
    );
  }
}

export default App;
