import React from "react";
import { inject, observer } from "mobx-react";
import { convertCurrencySummaryLocation } from "../helpers";
import { Translation } from "../Translation";
import { FiEdit2 } from "react-icons/fi";
import StarGreenIcon from "../icon/star_green.svg";

@inject("AppStore")
@observer
class SummaryStep extends React.Component {
  store = this.props.AppStore;

  render() {
    return (
      <div className="step-summary-content">
        <div className="left">
          <div className="heading">
            <h1>
              <span style={{ color: "#12B2AE" }}>
                {this.store.data.flat_name}
              </span>{" "}
              IS READY TO GO
            </h1>
          </div>

          <div
            className="image"
            style={{
              backgroundImage:
                this.store.summary.model.selected_picture &&
                `url(${this.store.summary.model.selected_picture})`,
            }}
          ></div>
        </div>
        <div className="options-wrapper">
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: this.store.data.name }}
          ></div>

          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div className="selectedModel__subtitle">ORDER SUMMARY</div>

              <div className="selectedModel__name">
                {this.store.summary.model.flat_name}
              </div>
            </div>
            <div className="upgrades">
              {Object.keys(this.store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        this.store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j} style={{ marginLeft: 10 }}>
                          {option_group_title !== "null" && (
                            <div className="upgradeoption-option-group-title">
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            this.store.summary.upgradeOptionsDisplay[step][
                            option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div className="upgradeoption-option-group">
                                {option_group}
                              </div>

                              {this.store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                >
                                  <span className="text">
                                    <div className="upgradeoption-option">
                                      <img
                                        src={StarGreenIcon}
                                        style={{ width: 12, height: 12, marginRight: 5 }}
                                      />
                                      <span>{option.title}</span>
                                    </div>
                                  </span>
                                  <span
                                    className="upgradeoption-edit"
                                    style={{
                                      cursor: "pointer",
                                      color: this.store.data.color,
                                      marginRight: 10,
                                      fontWeight: "bold",
                                      padding: 5,
                                    }}
                                    onClick={() => {
                                      this.store.goToOption(option);
                                    }}
                                  >
                                    <FiEdit2 />
                                  </span>

                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: this.store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      this.store.location
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation
                  lang={this.store.page.lang}
                  t="footer_purchase_price"
                />{" "}
                *
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  this.store.summary.purchase_price,
                  this.store.location
                )}
              </div>
            </div>
            <div
              className="purchase-price reservation"
              style={{ display: "none" }}
            >
              <div className="purchase-price-text">Reservation Fee</div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  this.store.summary.due_today_price,
                  this.store.location
                )}
              </div>
            </div>
            <div style={{ marginTop: 35, fontSize: 12 }}>
              *Purchase Price including selected options & delivery but excludes
              taxes, title and registration processing.
            </div>
          </div>
        </div>

        <div className="mobile">

          <div style={{ padding: '16px 0' }}>
            <div className="modelSelector__cleanbutton" >
              <a
                onClick={() => {
                  this.store.onStepSelected(
                    {},
                    this.store.page.pdfStep
                  )

                  setTimeout(() => {
                    var pdf = document.getElementById("pdf");
                    pdf.scrollIntoView()
                  }, 800);
                }}
              >
                SEND PDF
              </a>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default SummaryStep;
